
.StationSettings-btn {
  display: block;
  padding: 3px 8px;
  margin: 0 5px;
  border-radius: 5px;
  border: 1px solid #111;
  background: var(--primary-btn-bg);
  color: var(--primary-btn-fg);
}

.SSM-form {

}
