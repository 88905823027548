
.StationSettingsModal {
  position: absolute;
  z-index: 5;
  background: #eee;
  color: #333;
  border: 1px solid #333;
  margin-top: 5px;
  left: 5px;
  right: 5px;
  height: 300px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px #333;
  padding: 20px;
}
