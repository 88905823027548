
.UserTab {
  /*border: 1px solid red;*/
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--general-tab-height);
}

.UserTab-inner {
  position: relative;
}

.UserTab-title {
  text-decoration: underline;
}

.UserTab-key-val {
  display: flex;
  margin: 5px 0;
}

.UserTab-key,
.UserTab-val {
  display: block;
  /*margin: 0 5px 0 0;*/
}

.UserTab-key {
  width: 75px;
}

.UserTab-val {
  font-weight: bold;
}

.UserTab-input-order {
  display: block;

  margin: 5px 0;
  padding: 10px;
  font-size: 25px;
  width: 300px;
  border: 1px solid #333;
  border-radius: 5px;
}

.UserTab-btns {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.UserTab-btn {
  border: 1px solid #333;
  border-radius: 5px;
  display: block;
  margin: 5px;
  padding: 10px 15px;
}

.UserTab-btn-submit {
  color: var(--primary-btn-fg);
  background: var(--primary-btn-bg);
  font-size: 20px;
}

.UserTab-btn-signout {
  color: var(--danger-btn-fg);
  background: var(--danger-btn-bg);
  font-size: 15px;
  margin-top: 15px;
  padding: 10px 10px;
}

.UserTab-input-order:disabled,
.UserTab-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}


