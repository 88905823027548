
.CancelledOrdersModal {
  position: absolute;
  z-index: 10;
  border: 1px solid #999;
  background: #f8ba4e;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #333;
  bottom: -5px;
  left: -10px;
  right: -10px;
  text-align: center;
}

.COM-Title {
  font-size: 20px;
  margin: 5px 0;
}

.COM-Body {
  font-size: 16px;
  line-height: 1.4;
}

.COM-Btn {
  display: block;
  width: 80px;
  margin: 5px auto;
  text-align: center;
  background: #fefefe;
  color: #333;
  border: 1px solid #333;
  border-radius: 5px;
}

.COM-Btn:hover {
  background: #fff;
  color: #000;
  border-color: #000;
}
