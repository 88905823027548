
.Tabs {
  /*border: 1px solid orange;*/
}

.TabList {
  /*border: 1px solid red;*/
  display: flex;
}

.Tab-btn {
  display: block;
  position: relative;
  z-index: 1;
  background: #ccc;
  padding: 5px 10px;
  border: 1px solid #111;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.Tab-btn-active {
  background: #fefefe;
  bottom: -2px;
  padding-top: 7px;
}

.Tab-sign-in-btn {
  border: 1px solid #333;
  background: var(--primary-btn-bg);
  color: var(--primary-btn-fg);
  border-radius: 5px;
  bottom: 2px;
  padding-top: 5px;
  margin-left: auto;
}

.Tab-sign-in-btn.Tab-btn-active {
  bottom: 2px;
  padding-top: 5px;
}

.TabPanels {
  border: 1px solid #111;
}

.TabPanel {
  padding: 15px;
  background: #fefefe;
}

