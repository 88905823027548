* { box-sizing: border-box; }

:root {
  --primary-btn-bg: #3f4eae;
  --primary-btn-fg: #fefefe;

  --warning-btn-bg: orange;
  --warning-btn-fg: #fefefe;

  --danger-btn-bg: #921c1c;
  --danger-btn-fg: #fefefe;

  --general-tab-height: 400px;
}

body {
  margin: 0;
  padding: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #8bc2f5;
  color: #000;
}

::selection {
  background: pink;
}

::-moz-selection {
  background: pink;
}

a, a:visited {
  color: rgb(116, 30, 140);
}

a:hover {
  color: black;
}

a::selection {
  color: black;
  background: red;
}

a::-moz-selection {
  color: black;
  background: red;
}

pre, code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
  line-height: 1;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.4rem;
}

h6 {
  font-size: 1.3rem;
}

button {
  font-size: 1.3rem;
  display: inline-block;
  cursor: pointer;
}

button + button {
  margin-left: 0.5rem;
}

select,
input {
  font-size: 1.25rem;
  min-width: 14rem;
}

label {
  display: inline-block;
  text-align: right;
  font-weight: 600;
  min-width: 7rem;
  padding: 0 1rem 0 0;
}

[hidden] {
  display: none !important;
  visibility: hidden !important;
}

.InputArea {
  padding: 0;
  margin:  1rem 0;
  width:  30rem;
}

.ButtonArea {
  width: 30rem;
  text-align: center;
}

.Stage {
  padding: 0 1rem;
}
