
.StationInput-label {
  display: flex;
  /*align-items: center;*/
}

.StationInput-label-text {
  display: block;
  font-weight: bold;
  align-self: center;
  margin: 0 5px;
}

.StationInput-value-text {
  display: block;
  align-self: center;
  margin: 0 5px;
}

.StationInput-select {
  display: block;
  border: 1px solid #333;
  border-radius: 5px;
  margin: 0 5px;
}

.StationInput-btn {
  display: block;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 3px 8px;
  background: var(--primary-btn-bg);
  color: var(--primary-btn-fg);
}

.StationInput-btn-change {
  background: #cb9551;
  color: #333;
}

.StationInput-btn-change:disabled {
  background: #5c5c5c;
  color: #cdcdcd;
  text-decoration: line-through;
  cursor: not-allowed;
}
