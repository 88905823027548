
.SSM-form {
  display: flex;
  margin: 15px 0;
  align-items: center;
}

.SSM-label,
.SSM-btn,
.SSM-input,
.SSM-note {
  display: block;
}

.SSM-label {
  width: 210px;
}

.SSM-btn {
  border: 1px solid #333;
  border-radius: 5px;
}

.SSM-btn-icon {
  background: #91cde5;
  padding: 3px 10px 2px 10px;
}

.SSM-btn-icon-minus {
  border-radius: 5px 0 0 5px;
}

.SSM-btn-icon-plus {
  border-radius: 0 5px 5px 0;
  margin: 0 5px 0 0;
}

.SSM-input {
  border: 1px solid #333;
  border-left: 0;
  border-right: 0;
  padding: 3px 8px;
  width: 100px;
  min-width: 100px;
  text-align: center;
}

.SSM-btn-submit {
  background: var(--primary-btn-bg);
  color: var(--primary-btn-fg);
  margin-left: 10px;
}

.SSM-btn-submit:disabled {
  background: #696b87;
  color: #ccc;
  text-decoration: line-through;
  cursor: not-allowed;
}

.SSM-btn-reset {
  background: var(--warning-btn-bg);
  color: var(--warning-btn-fg);
}

.SSM-btn-reset:disabled {
  display: none;
}
