
.App-bg-title {
  position: absolute;
  opacity: 0.35;
  color: #000;
  text-transform: uppercase;
  font-size: 4.5rem;
  line-height: 0;
  top: 4.75rem;
  right: 0.5rem;
}

.App-title {
  font-size: 2rem;
  line-height: 1;
  padding: 0 0 0.25rem 0.25rem;
  margin: 0 0 0.25rem;
  border-bottom: 3px solid #000;
}

.App-container {
  border: 1px solid orange;
  max-width: 1100px;
}

.App-station-header {
  position: relative;
  background: #eeeeee;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.App-station-body {
  padding: 5rem;
}

.App-station-body-inactive {
  background: #fba7b5;
}

.App-station-body-active {
  background: #abeaab;
}
