
.SignInForm-wrapper {
  background: #b9bfee;
  padding: 15px;
  border: 1px solid #111;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--general-tab-height);
}

.SignInForm-label {
  display: block;
  text-align: left;
}

.SignInForm-input {
  display: block;
  width: 300px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  margin: 5px 0;
}

.SignInForm-btn {
  border: 1px solid #333;
  background: var(--primary-btn-bg);
  color: var(--primary-btn-fg);
  padding: 10px 15px;
  border-radius: 5px;
}
